import React from 'react'
import PropTypes from 'prop-types'
import './ActivitySection.scss'

const ActivitySection = ({ content, sectionId, url, isReverse, givenRef }) => {
  const title = content.title
  const exergue = content.exergue
  const body = content.body
  return (
    // <div  className='sal-wrapper' >
    <section id={sectionId} ref={givenRef} className='activity-section'>
      <div className='grid activity-section-wrapper'>
        <span className='surtitle hide-on-desktop' style={{ color: '#0039cb' }}>Nos activités</span>
        <div className={`activity-section-content${isReverse ? '-reverse' : ''} content`}>
          <h2>{title}</h2>
          <p className='content-exergue'>{exergue}</p>
          {body.map ? body.map((p, i) => <p className='content-body' key={i}>{p}</p>) : <p className='content-body'>{body}</p>}
          {url && <span className='btn-invert'><a href={url}>En savoir plus</a></span>}
        </div>
        <div className={`activity-section-mosaic${isReverse ? '-reverse' : ''}`}>
          <div className='grid-mosaic'>
            <div className='img1-container'></div>
            <div className='img2-container'></div>
            <div className='img3-container'></div>
          </div>
        </div>
      </div>
    </section>
    // </div>
  )
}

ActivitySection.propTypes = {
  isReverse: PropTypes.bool
}

ActivitySection.defaultProps = {
  isReverse: false
}

export default ActivitySection
