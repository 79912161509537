import React, { useState, useEffect, useRef } from 'react'
import './TestimonySection.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import VideoContainer from '../VideoContainer'
// B-Smart
import videoBsmart from '../../assets/temoignages_video_bsmart.mp4'
import posterBsmart from '../../assets/temoignages_video_bsmart.png'
// Bar de l'Economie - https://www.linkedin.com/video/live/urn:li:ugcPost:6909065971253071872/
import videoBareconomie from '../../assets/temoignages_video_bareconomie.mp4'
import posterBareconomie from '../../assets/temoignages_video_bareconomie.png'
// Ekopo
import videoEkopo from '../../assets/temoignages_video_ekopo.mp4'
import posterEkopo from '../../assets/temoignages_poster_ekopo.jpeg'
// Paris Habitat
import videoParisHabitat from '../../assets/temoignages_video_parishabitat.mp4'
import posterParisHabitat from '../../assets/temoignages_poster_parishabitat.png'
// La Poste
import videoLaPoste from '../../assets/temoignages_video_laposte.mp4'
import posterLaPoste from '../../assets/temoignages_poster_laposte.jpg'
// Mot du Dirigeant
import videoMotDirigeant from '../../assets/temoignages_video_motdirigeant.mp4'
import posterMotDirigeant from '../../assets/temoignages_poster_motdirigeant.jpg'

const swapperContent = [
  { type: 'video', src: videoMotDirigeant, poster: posterMotDirigeant },
  { type: 'video', src: videoBsmart, poster: posterBsmart },
  { type: 'video', src: videoBareconomie, poster: posterBareconomie },
  { type: 'video/vimeo', src: videoEkopo, poster: posterEkopo },
  { type: 'video/vimeo', src: videoParisHabitat, poster: posterParisHabitat },
  { type: 'quote', content: '« C’est bien que la métropole s’occupe de l’environnement et en fasse un peu plus »', author: 'Christian B.', status: 'Habitant', ville: 'Montpellier', projet: 'Montpellier Méditerranée Métropole' },
  { type: 'video/vimeo', src: videoLaPoste, poster: posterLaPoste },
  { type: 'quote', content: '« Je suis contente de m’être rendue utile à ce projet. Je travaille sur ce site depuis 11 ans, et cela m’a motivée à faire encore plus pour les gens »', author: 'Nacéra', status: 'Gardienne d’une résidence', ville: 'Vénissieux', projet: 'Alliade Habitat' },
]

const TestimonySection = () => {
  const [swapperIndex, setSwapperIndex] = useState(0)
  const swapperRef = useRef()

  const handleClickRight = () => {
    if (swapperRef.current.scrollLeft + swapperRef.current.offsetWidth < swapperRef.current.children[0].offsetWidth) {
      setSwapperIndex(swapperIndex + 1)
    }
    // console.log('la', swapperRef.current.scrollWidth / swapperRef.current.children[0].offsetWidth)
  }
  const handleClickLeft = () => {
    if (swapperRef.current.scrollLeft > 0) {
      setSwapperIndex(swapperIndex - 1)
    }
  }

  useEffect(() => {
    swapperRef.current.scrollTo({ left: (swapperRef.current.children[0].offsetWidth / swapperContent.length) * swapperIndex, behavior: 'smooth' });
  }, [swapperIndex])

  return (
    <section id='testimony-section'>
      <div className='grid testimony-section-wrapper'>
        <span className='surtitle hide-on-desktop' style={{ paddingBottom: '40px' }}>Témoignages</span>
        <div className='swaper-button swaper-button-left'>
          <div className='btn-guide' onClick={handleClickLeft}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        </div>
        <div ref={swapperRef} className='swaper-body'>
          <div style={{ width: swapperRef.current && swapperContent.length * swapperRef.current?.offsetWidth, height: '100%', display: 'flex' }}>
            {
              swapperContent.map((item, index) => {
                if (item.type === 'video/vimeo' || item.type === 'video') {
                  return (
                    <div key={index} style={{ width: swapperRef.current?.offsetWidth, outline: '20px solid #f5f5f5' }}>
                      <VideoContainer key={index} src={item.src} poster={item.poster} index={index} currentIndex={swapperIndex} />
                    </div>
                  )
                } else if (item.type === 'quote') {
                  return (
                    <div key={index} style={{ width: swapperRef.current && swapperRef.current?.offsetWidth }} className='testimony-card'>
                      <div className='testimony-content'>
                        <div className='content'>
                          <p className='content-exergue'>{item.content}</p>
                          <p className='content-body'>{item.author}, {item.status}</p>
                        </div>
                        <div className='testimony-meta'>
                          <div className='planet-picto'></div>
                          <div>
                            <span className='meta-name'>Ville</span>
                            <span className='meta-value'>{item.ville}</span>
                          </div>
                          <div>
                            <span className='meta-name'>Projet</span>
                            <span className='meta-value'>{item.projet}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                } else {
                  return null
                }
              })
            }
          </div>
          {/* <div className='grid'>
            <div className='testimony-card'>
              <div className='testimony-content'>
                <div className='content'>
                  <p className='content-exergue'>E3D-Environnement est une entreprise spécialisée dans l’accompagnement des changements de comportements.</p>
                  <p className='content-body'>Ses solutions innovantes permettent à chacun de changer ses habitudes : réduire ses consommations d’énergie et d’eau, sa production de déchets, trier mieux, composter.</p>
                </div>
                <div className='testimony-meta'>
                  <div className='planet-picto'></div>
                  <div>
                    <span className='meta-name'>Ville</span>
                    <span className='meta-value'>Montpellier</span>
                  </div>
                  <div>
                    <span className='meta-name'>Projet</span>
                    <span className='meta-value'>Défi-éco-responsable</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='testimony-figure'></div>
          </div> */}
        </div>
        <div className='swaper-button swaper-button-right'>
          <div className='btn-guide' onClick={handleClickRight}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
        <div className='slide-guide'>
          <div className='slide-guide-wrapper'>
            {
              swapperContent.map((el, index) => {
                return (
                  <div key={index} className='guide'>
                    <div style={{ left: index * -110 + 'px' }}>
                      {
                        swapperContent.map((element, i) => <div key={index + '-' + i}></div>)
                      }
                      <div className='active' style={{ left: swapperIndex * 110 + 'px' }}></div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default TestimonySection
