import React from 'react'
import './ScrollToTop.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'

const ScrollToTop = ({ display }) => {
  return (
    <div className={`scroll-top-wrapper ${display ? 'show-scroll-top' : 'hidden-scroll-top'}`}>
      <div className='scroll-top' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
        <FontAwesomeIcon icon={faArrowUp} />
      </div>
    </div>
  )
}

export default ScrollToTop
