import './COVIDPage.scss'

import React from 'react'
import Headbar from '../../components/Headbar'
import Footer from '../../components/Footer'

import test2 from '../../test2.png'
import xn from '../../assets/xavier-normand.jpg'

const para1 = `Cher lecteur,<br/><br/>Cette seconde vague de confinement sera difficile pour le moral des habitants, l’économie locale, le lien social… Le prolongement de la crise sanitaire a des conséquences déjà perceptibles sur les liens sociaux. Vous, élus locaux, êtes en première ligne pour accompagner les habitants et faire face à des remises en question ou même des conflits.`

const para2 = `Vous devez garder le contact avec les habitants confinés? En particulier les plus fragiles et qui ne sont <strong>pas identifiés par les structures d’aide (CCAS etc.) ou qui ne vont pas sur les plateformes d'entraide?</strong> Vous avez besoin de connaître et répondre aux besoins de tous les habitants ? Vous souhaitez les aider à <strong>renforcer leur capital santé ?</strong> Où simplement <strong>faire vivre une politique locale</strong> en étant entendu et reconnu par les habitants ?`

const citation = `<strong>Témoignage de Xavier Normand –, Maire de Castanet-Tolosan,</strong> Vice-Président à l’innovation d’un syndicat de gestion de déchets couvrant un territoire d’1 million d’habitants<br/> <br/><q>Pendant le 1er confinement je n’étais pas élu, mais <strong>j’étais citoyen volontaire pour appeler des gens.</strong> On a utilisé le fichier des plans canicules pour les numéros de téléphone. C’était très intéressant, très humain… mais <strong>il nous manquait une approche structurée pour être réellement productifs.</strong> A la fin de nos échanges nous étions restés souvent sur des banalités. Aujourd’hui en tant qu’élu je me dis que <strong>nous pourrions être plus efficaces avec une méthode et des outils adaptés pour aller au-delà.</strong> Bonne nouvelle, les premiers retours d’expérience sur nos communes auprès des habitants montrent que <strong>E3D-Environnement a les outils et l'expérience pour aller au-delà.</strong></q>`

const para6 = `E3D-Environnement propose de mettre ses <strong>10 années de savoir-faire dans l’accompagnement à grande échelle des populations</strong> au service des élus locaux pour :<br/><br/>
<li><strong>Garder un lien avec chaque habitant</strong> pendant cette période si particulière</li>
<li>Promouvoir des actions simples auprès des habitants pour <strong>améliorer leur capital santé (alimentation, sport)</strong></li>
<li>Impulser une <strong>dynamique d'entraide et de cohésion</strong> entre habitants et avec leurs élus</li>
<li><strong>Identifier les habitants fragiles</strong> et les accompagner ou bien les rediriger vers des structures spécialisées (CCAS etc.) pour les cas les plus complexes</li>
<li>Donner de nouveaux outils utilisables par les élus, sans intermédiaire, pour <strong>avoir un retour d’information en temps réel sur les actions, besoins, et réactions des habitants</strong> </li>`

const para7 = `Vous aussi vous souhaitez mettre en place rapidement une solution<br/>d’accompagnement de vos administrés ?<br/><br/>Contactez-nous !<br/>04.42.68.28.99<br/>covid-19@e3d-environnement.com`

const COVIDPage = ({ setIsOpenDrawer, displayCollapse, setDisplayCollapse }) => {

  return (
    <div id='covid-page' className='App'>
      <Headbar logo={test2} setIsOpen={setIsOpenDrawer} displayCollapse={displayCollapse} setDisplayCollapse={setDisplayCollapse} />
      {/* <AboutSection sectionId='gd6d-about-section' content={ main }/> */}
      <div className='covid-page__content grid'>
        <div className='covid-page__content__p'>
          <p dangerouslySetInnerHTML={{ __html: para1 }} className='exergue'></p>
          <p dangerouslySetInnerHTML={{ __html: para2 }}></p>
          <div className='covid-page__content__p__testimony'>
            <div>
              <div className='covid-page__content__p__testimony__img'>
                <img src={xn} alt='Photographie de Xavier Normand' />
              </div>
            </div>
            <p className='covid-page__content__p__testimony__p' dangerouslySetInnerHTML={{ __html: citation }}></p>
          </div>
          <ul dangerouslySetInnerHTML={{ __html: para6 }}></ul>
          <p dangerouslySetInnerHTML={{ __html: para7 }} className='exergue2'></p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default COVIDPage
