import './Footer.scss'
import React, { useState } from 'react';
import MentionsLegales from './MentionsLegales'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
  const urlE3D = ""
  const [isMentionsLegales, isMentionsLegalesSet] = useState(false);
  const showMentionsLegales = () => {
    isMentionsLegalesSet(true)
  }
  const closeMentionsLegales = () => {
    isMentionsLegalesSet(false)
  }

  return (
    <footer>
      <div className='bottom-of-site'></div>
      <div>
        <ul className='contact'>
          <li><strong>E3D-ENVIRONNEMENT</strong></li>
          <li>1 place Martin Luther King,13090 Aix-en-Provence</li>
          <li className='menu-item-rs'>
            <span className='menu-rs'>
              {false && <a href={urlE3D}><FontAwesomeIcon icon={faTwitter} /></a>}
              <a href="https://www.linkedin.com/company/e3d-environnement" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
              {false && <a href={urlE3D}><FontAwesomeIcon icon={faYoutube} /></a>}
              <a href="mailto:contact@e3d-environnement.com" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faEnvelope} /></a>
              <a href="tel:+33442680899" target="_blank" rel="noopener noreferrer">04 42 68 08 99</a>
            </span>
          </li>
        </ul>
      </div>
      <div className='footer-mentions'>
        <span>©2023 E3D-Environnement - Crédits</span>
        {
          false && <span>
            <a className='footer-link' onClick={showMentionsLegales}>Mentions légales</a> - <a className='footer-link'>RGPD</a>
          </span>
        }
        {
          true && <span>
            <a className='footer-link' onClick={showMentionsLegales}>Mentions légales</a>
          </span>
        }
        <MentionsLegales
          open={isMentionsLegales}
          handleClose={closeMentionsLegales}
        />
      </div>
    </footer>
  )
}

export default Footer
