import React, { useState } from 'react'
import './QuoteSection.scss'

const QuoteSection = () => {
  const [showFigure, setShowFigure] = useState(false)

  // useEffect(() => {
  //   const timeout = setTimeout(() => setShowFigure(!showFigure), 4500)

  //   return () => clearTimeout(timeout)
  // }, [showFigure])

  return (
    <div className='safe-zone-quote'>
      <div className='grid'>
        <div className='quote-wrapper'>
          <div className={`quote-content quote-content-${showFigure ? 'down' : 'up'}`}>
            <div>
              <div className='quote'>“Soyez le changement<br />que vous voulez voir<br />dans le monde”</div>
            </div>
            <div className='author'>Gandhi</div>
            <div>
              <div className='quote-figure'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuoteSection
