import React from 'react'
import './MainSection.scss'

import PropTypes from 'prop-types'

import video from '../../assets/e3d-video_final.mp4'
import poster from '../../assets/main-image.jpg'

const MainSection = ({ sectionId }) => {
  return (
    <section id={sectionId}>
      <div className='main-section-bg'>
        <video poster={poster} id="bgvid" muted loop autoPlay>
          <source type="video/mp4" src={video} />
        </video>
      </div>
      <div className='grid main-section-wrapper'>
        <div className='main-section-surtitle'>
          <h4 className='color-white'>Notre métier</h4>
        </div>
        <div className='main-section-title'>
          <h1 className='color-white'>Soutenir la transition écologique<br/>par les changements de comportements</h1>
        </div>
      </div>
    </section>
  )
}

MainSection.propTypes = {
  sectionId: PropTypes.string,
}

export default MainSection
