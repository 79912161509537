import React from 'react'
import './E3DPage.scss'

import Headbar from '../../components/Headbar'

import test2 from '../../test2.png'

const E3DPage = ({ setIsOpenDrawer }) => {

  return (
    <div className='App'>
      <Headbar logo={test2} setIsOpen={setIsOpenDrawer} />
      <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        E3DPage
      </div>

    </div>
  )
}

export default E3DPage
