import React, { useState, useEffect, useRef } from 'react'
import './ActuSection.scss'

import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons'

const ActuSection = ({ refActualite }) => {
  const [articles, setArticles] = useState([])
  const [isScrolling, setIsScrolling] = useState(false)
  const [clientX, setClientX] = useState(0)
  const [scrollX, setScrollX] = useState(0)
  const ref = useRef()

  useEffect(() => {
    let timeout
    if (window.innerWidth < 1024) {
      timeout = setInterval(() => {
        if (ref.current.scrollLeft < ref.current.scrollWidth - ref.current.offsetWidth) {
          const scroll = ref.current.scrollLeft + 275
          ref.current.scrollTo({ left: scroll, behavior: 'smooth' })
        } else {
          ref.current.scrollTo({ left: 0, behavior: 'smooth' })
        }
      }, 2500)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [window.innerWidth])

  useEffect(() => {
    axios.get('https://www.e3d-environnement.com/json/index.php')
      .then(res => {
        const data = res.data
        setArticles(data.slice(0, 5))
      })
      .catch(err => console.log("Erreur API", err))
  }, [])

  const handleMouseDown = e => {
    setIsScrolling(true)
    setClientX(e.clientX)
  }
  const handleMouseUp = () => {
    setIsScrolling(false)
  }
  const handleMouseMove = e => {
    if (isScrolling) {
      const scrollValue = scrollX + clientX - e.clientX
      const scrollValueConstrain = Math.min(Math.max(0, scrollValue), ref.current.children[0].offsetWidth)
      ref.current.scrollLeft = scrollValueConstrain

      setScrollX(scrollValueConstrain)
      setClientX(e.clientX)
    }
  }

  return (
    <section id='actu-section' ref={refActualite}>
      <div className='grid '>
        <span className='surtitle'>Notre actu</span>
      </div>
      <div
        ref={ref}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseOut={handleMouseUp}
        className='articles-wrapper'
      >
        <div className='grid'>
          {articles.map((article, i) => (
            <div key={i} className={`article article-${i + 1}`} >
              <div className='article-figure' style={{ background: `url(${article.image}) #bdbdbd no-repeat center center`, backgroundSize: 'cover' }}></div>
              <div className='article-link'><a href={article.url} target='blank'><FontAwesomeIcon icon={faNewspaper} /></a></div>
              <div className='article-content'><div><p>{article.text}</p></div></div>
            </div>
          ))
          }
        </div>
      </div>
    </section>
  )
}

export default ActuSection
