import React, { useState, useEffect, useRef } from 'react'
import './RefSection.scss'

import bordeaux from '../../assets/bordeaux-métropole.png'
import lyon from '../../assets/lyon-metropole.png'
import montpellier from '../../assets/montpellier-metropole.png'
import parisHab from '../../assets/paris-habitat.png'
import paris from '../../assets/paris.png'
import rouen from '../../assets/rouen-metropole.jpg'
import marseille from '../../assets/marseille-metropole.jpeg'
import europe from '../../assets/europe.png'
import beziers from '../../assets/beziers.jpg'
import sicoval from '../../assets/sicoval.png'
import sirtomad from '../../assets/sirtomad.png'
import roissy from '../../assets/roissy.png'

const RefSection = () => {
  const [isScrolling, setIsScrolling] = useState(false)
  const [clientX, setClientX] = useState(0)
  const ref = useRef()


  const handleMouseDown = e => {
    setIsScrolling(true)
    setClientX(e.clientX)
  }
  const handleMouseUp = () => {
    setIsScrolling(false)
  }
  const handleMouseMove = e => {
    if (isScrolling) {
      const scrollValue = ref.current.scrollLeft - e.clientX + clientX
      const scrollValueConstrain = Math.min(Math.max(0, scrollValue), ref.current.children[0].offsetWidth)
      ref.current.scrollLeft = scrollValueConstrain
      setClientX(e.clientX)
    }
  }

  useEffect(() => {
    const timeout = setInterval(() => {
      if (ref.current.scrollLeft < ref.current.scrollWidth - ref.current.offsetWidth) {
        const scroll = ref.current.scrollLeft + 275
        ref.current.scrollTo({ left: scroll, behavior: 'smooth' })
      } else {
        ref.current.scrollTo({ left: 0, behavior: 'smooth' })
      }
    }, 2500)

    return () => {
      clearTimeout(timeout)
    }
  }, [])


  return (
    <section id='ref-section'>
      <div className='grid'>
        <span className='surtitle'>références</span>
      </div>
      <div
        ref={ref}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseOut={handleMouseUp}

        className='references-wrapper'
      >
        <div className='references'>
          <div className='ref-card'>
            <div style={{ background: `url(${bordeaux}) no-repeat center`, backgroundSize: 'contain', height: '100%' }}></div>
          </div>
          <div className='ref-card'>
            <div style={{ background: `url(${lyon}) no-repeat center`, backgroundSize: 'contain', height: '100%' }}></div>
          </div>
          <div className='ref-card'>
            <div style={{ background: `url(${montpellier}) no-repeat center`, backgroundSize: 'contain', height: '100%' }}></div>
          </div>
          <div className='ref-card'>
            <div style={{ background: `url(${parisHab}) no-repeat center`, backgroundSize: 'contain', height: '100%' }}></div>
          </div>
          <div className='ref-card'>
            <div style={{ background: `url(${paris}) no-repeat center`, backgroundSize: 'contain', height: '100%' }}></div>
          </div>
          <div className='ref-card'>
            <div style={{ background: `url(${rouen}) no-repeat center`, backgroundSize: 'contain', height: '100%' }}></div>
          </div>
          <div className='ref-card'>
            <div style={{ background: `url(${marseille}) no-repeat center`, backgroundSize: 'contain', height: '100%' }}></div>
          </div>
          <div className='ref-card'>
            <div style={{ background: `url(${europe}) no-repeat center`, backgroundSize: 'contain', height: '100%' }}></div>
          </div>
          <div className='ref-card'>
            <div style={{ background: `url(${beziers}) no-repeat center`, backgroundSize: 'contain', height: '100%' }}></div>
          </div>
          <div className='ref-card'>
            <div style={{ background: `url(${sicoval}) no-repeat center`, backgroundSize: 'contain', height: '100%' }}></div>
          </div>
          <div className='ref-card'>
            <div style={{ background: `url(${sirtomad}) no-repeat center`, backgroundSize: 'contain', height: '100%' }}></div>
          </div>
          <div className='ref-card'>
            <div style={{ background: `url(${roissy}) no-repeat center`, backgroundSize: 'contain', height: '100%' }}></div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default RefSection
