import React, { useState, useEffect } from 'react'
import './App.scss'

import { useLocation } from 'react-router-dom'

import Headbar from './components/Headbar'
import Drawer from './components/Drawer'
import HomePage from './pages/HomePage'
import GD6DPage from './pages/GD6DPage'
import E3DPage from './pages/E3DPage'
import COVIDPage from './pages/COVIDPage'
import ScrollToTop from './components/ScrollToTop'

const App = () => {
  const location = useLocation()
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const [displayScrollTop, setDisplayScrollTop] = useState(false)
  const [displayCollapse, setDisplayCollapse] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScrollTop)

    return () => {
      window.removeEventListener('scroll', handleScrollTop)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    setIsOpenDrawer(false)
    setDisplayCollapse(false)
  }, [location.pathname])

  useEffect(() => {
    if (displayCollapse) {
      const scrollPos = window.pageYOffset // Attrape le niveau de scroll
      console.log(scrollPos)
      document.body.classList.add('drawer-is-open') // Ajoute une class qui rend fixe le body
      document.body.style.top = -1 * scrollPos + 'px' // Ajoute le niveau de scroll au 'top' du body fixe
    } else {
      document.body.classList.remove('drawer-is-open') // Enleve la class modal-open de mon obdy
      let value = window.document.body.style.top // Recupère mon niveau de scroll d'avant l'ajout d'un position fixe à mon document
      value = value.replace('px', '')
      window.scrollTo(0, -1 * value) // Remet le scroll en place
    }
  }, [displayCollapse])

  const handleScrollTop = () => {
    const scroll = window.scrollY
    if (scroll > 600) setDisplayScrollTop(true)
    else setDisplayScrollTop(false)
  }

  // A mettre dans une librairie
  function getAnchor(currentUrl) {
    const urls = currentUrl.split('#')
    return urls[1] ? urls[1] : null;
  }
  function getWantedPage(currentUrl, appliUrl) {
    // 0- Couper les anchors
    const urls = currentUrl.split('#')
    currentUrl = urls[0];
    //console.log("getWantedPage" , currentUrl , appliUrl );
    // 1- Vérifier si paramètre "page"
    const url = new URL(currentUrl);
    const page = url.searchParams.get("page");
    //console.log("PAGE" , page);
    if (page && page.length > 0) {
      return page;
    }
    // 2- Couper l'URL et envoyer la fin
    const urlParts = currentUrl.split(appliUrl);
    //console.log("URL_PARTS" , urlParts);
    if (urlParts.length === 2 && urlParts[1].length > 0) {
      return urlParts[1];
    }
    // Default
    return "";
  }

  let pageHOME = "HOME";
  let pageGD6D = "GD6D";
  let pageE3D = "E3D";
  let pageCOVID = "COVID";
  function givePage() {
    let page = process.env.REACT_APP_PAGE ? process.env.REACT_APP_PAGE : null;
    page = page ? page.toUpperCase() : null;
    if (page === "GD6D") {
      return pageGD6D;
    }
    if (page === "E3D") {
      return pageE3D;
    }
    if (page === 'COVID') {
      return pageCOVID
    }
    //return pageGD6D;
    return pageHOME;
  }

  //const appliUrl = process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "http://localhost:3001/";
  //const firstPage = getWantedPage(window.location.href, appliUrl);
  //console.log(firstPage)
  //console.log(getAnchor(window.location.href) );

  let page = givePage();
  //console.log("REACT_PAGE" , process.env.REACT_APP_PAGE, page);

  return (
    <>
      {/* <Headbar
        logo={test2}
        setIsOpen={ setIsOpenDrawer }
        displayCollapse={ displayCollapse }
        setDisplayCollapse={ setDisplayCollapse }
        isFixed
      /> */}
      <Drawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer} />
      {page === pageHOME && <HomePage setIsOpenDrawer={setIsOpenDrawer} displayCollapse={displayCollapse} setDisplayCollapse={setDisplayCollapse} />}
      {page === pageGD6D && <GD6DPage setIsOpenDrawer={setIsOpenDrawer} displayCollapse={displayCollapse} setDisplayCollapse={setDisplayCollapse} />}
      {page === pageCOVID && <COVIDPage setIsOpenDrawer={setIsOpenDrawer} displayCollapse={displayCollapse} setDisplayCollapse={setDisplayCollapse} />}
      {page === pageE3D && <E3DPage setIsOpenDrawer={setIsOpenDrawer} />}
      <ScrollToTop display={displayScrollTop} />
    </>
  )
}

export default App
