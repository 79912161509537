import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './KeyFigureCard.scss'

const KeyFigureCard = ({ value, name, legend, incrementConfig }) => {

  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    // Auto-increment dy compteur
    if (incrementConfig) {

      let currTime = new Date();
      let delta = currTime - incrementConfig.start;
      let nbInc = delta / incrementConfig.delay;
      let v = parseInt(nbInc * incrementConfig.inc);
      //console.log( v , nbInc , delta , currTime , incrementConfig.start );
      setCurrentValue(v);

      setInterval(() => {
        try {
          let currTime = new Date();
          let delta = currTime - incrementConfig.start;
          let nbInc = delta / incrementConfig.delay;
          let v = parseInt(nbInc * incrementConfig.inc);
          //console.log( v , nbInc , delta , currTime , incrementConfig.start );
          setCurrentValue(v);
        } catch (error) { console.log(error) }
      }, 10 * 1000)
    }


  }, [incrementConfig])

  const numberWithCommas = (x) => {
    x = x.toString();
    const pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) {
      x = x.replace(pattern, "$1.$2");
    }
    return x;
  }

  return (
    <div className='key-figure-card'>
      <div className='key-figure-card-body'>
        <span className='key-figure-value'>{numberWithCommas(currentValue)}</span>
        <span className='key-figure-name'>{name}</span>
      </div>
    </div>
  )
}

KeyFigureCard.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  legend: PropTypes.string,
}

export default KeyFigureCard
