import React, { useEffect, useRef } from 'react'
import './VideoContainer.scss'

const VimeoContainer = ({ src, poster, index, currentIndex }) => {
  const ref = useRef()

  useEffect(() => {
    if (index !== currentIndex) ref.current.pause()
  }, [currentIndex, index])

  return (
    <div className='video-container'>
      <video ref={ref} poster={poster} controls playsInline controlsList='nodownload' preload='metadata' disablePictureInPicture>
        <source src={src} type='video/mp4' />
      </video>
    </div>
  )
}

export default VimeoContainer
