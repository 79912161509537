import React from 'react'
import './AboutSection.scss'

import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

const AboutSection = ({ sectionId, givenRef, content }) => {
  let url = null;
  return (
    <section id={sectionId} className='' ref={givenRef}>
      <div className='grid about-section-wrapper'>
        <span className='surtitle hide-on-desktop'>A propos</span>
        <div className='about-section-content content'>
          <p className='content-exergue'>{content.exergue}</p>
          {content.body?.map((paragraphe, i) => <p key={i} className='content-body'>{paragraphe}</p>)}
          {url && <span className='content-link'><a href={url}>En savoir plus</a><FontAwesomeIcon icon={faChevronRight} /></span>}
        </div>
        <div className='about-section-mosaic'>
          <div className='grid-mosaic'>
            <div className='img1-container'></div>
            <div className='img2-container'></div>
            <div className='img3-container'></div>
          </div>
        </div>
      </div>
    </section>
  )
}

AboutSection.propTypes = {
  sectionId: PropTypes.string,
  givenRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
}

export default AboutSection
