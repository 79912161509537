import React, { useState, useEffect, useRef } from 'react'
import './HomePage.scss'

import Headbar from '../../components/Headbar'
import MainSection from '../../components/MainSection'
import KeyFigures from '../../components/KeyFigures'
import AboutSection from '../../components/AboutSection'
import ActuSection from '../../components/ActuSection'
import ActivitySection from '../../components/ActivitySection'
import TestimonySection from '../../components/TestimonySection'
import RefSection from '../../components/RefSection'
import QuoteSection from '../../components/QuoteSection'
import Footer from '../../components/Footer'

import test2 from '../../test2.png'
import test from '../../test.png' // A changer (meilleurs couleurs + format svg)

import content from '../../content/content-homepage.json'

const aboutSectionContent = content.about
const gd6dSectionContent = content.gd6d
const innovationSectionContent = content.innovation

const scrollToRef = (ref) => window.scrollTo({ left: 0, top: ref.current.offsetTop, behavior: 'smooth' })

const HomePage = ({ setIsOpenDrawer, displayCollapse, setDisplayCollapse }) => {
  const [blocs, setBlocs] = useState([])
  const [elements, setElements] = useState({})

  const defineBloc = () => {
    const elements = document
      .getElementsByClassName('bloc')

    let blocsEl = []
    for (let i = 0; i < elements.length; i++) {
      blocsEl.push({ posY: elements[i].offsetTop, height: elements[i].offsetHeight })
    }
    setBlocs(blocsEl)
    console.log(blocsEl)
  }
  const getElements = () => {
    const main = document.getElementById('main-section')
    const about = document.getElementById('about-section-container')
    const actu = document.getElementById('actu-section-container')
    const activity = document.getElementById('activity-section-container')
    const testimony = document.getElementById('testimony-section-container')
    const ref = document.getElementById('ref-section-container')

    const gd6d = document.getElementById('gd6d')
    const innovation = document.getElementById('innovation')

    let els = {}
    els.main = { posY: main.offsetTop, height: main.offsetHeight }
    els.about = { posY: about.offsetTop, height: about.offsetHeight }
    els.actu = { posY: actu.offsetTop, height: actu.offsetHeight }
    els.activity = { posY: activity.offsetTop, height: activity.offsetHeight }
    els.testimony = { posY: testimony.offsetTop, height: testimony.offsetHeight }
    els.ref = { posY: ref.offsetTop, height: ref.offsetHeight }
    els.gd6d = { posY: gd6d.offsetTop, height: gd6d.offsetHeight }
    els.innovation = { posY: innovation.offsetTop, height: innovation.offsetHeight }

    setElements(els)
    //console.log(els)
  }
  useEffect(() => {
    defineBloc()
    getElements()
    opacityOfTitle()
    // Gestion des scrolls
    const urls = window.location.href.split('#')
    const anchor = urls[1] ? urls[1] : null;
    if (anchor === 'about') { setTimeout(() => { scrollToRef({ top: blocs[1].posY }) }, 500) }
    if (anchor === 'actualite') { setTimeout(() => { scrollToRef({ top: blocs[2].posY }) }, 500) }
    if (anchor === 'gd6d') { setTimeout(() => { scrollToRef(refGd6d) }, 500) }
    if (anchor === 'innovation') { setTimeout(() => { scrollToRef(refInnovation) }, 500) }
  }, [])
  useEffect(() => {
    const init = () => {
      defineBloc()
      getElements()
    }
    window.addEventListener('scroll', opacityOfTitle)
    window.addEventListener('resize', init)
    return (() => {
      window.removeEventListener('resize', init)
      window.removeEventListener('scroll', opacityOfTitle)
    })
  }, [blocs, elements])

  const giveOpacityWithTransition = (minHeight, maxHeight, currentHeight) => {
    let ratioHeight = 25;
    if (currentHeight === 0) {
      return 0;
    }
    let ratio = 100 * (currentHeight - minHeight) / (maxHeight - minHeight);

    // Gestion en rampe   0-1 => 1 => 1-0 selon ratioHeight
    if (ratio < 0) {
      return 0;
    }
    if (ratio >= ratioHeight && ratio <= 100 - ratioHeight) {
      return 1;
    }
    if (ratio <= ratioHeight) {
      return (ratio / ratioHeight);
    }
    if (ratio <= 100) {
      return 0.4 + ((100 - ratio) / ratioHeight);
    }
    if (ratio > 100) {
      return 0.4
    }
    return 0;
  }

  const giveColorWithTransition = (minHeight, maxHeight, currentHeight) => {
    if (currentHeight >= minHeight - 40 && currentHeight <= maxHeight - 40) {
      return '#fff'
    } else {
      return '#2979ff'
    }

  }

  const opacityOfTitle = () => {
    const aboutTitle = document.getElementById('about-section-title')
    const actuTitle = document.getElementById('actu-section-title')
    const activityTitle = document.getElementById('activity-section-title')
    const testimonyTitle = document.getElementById('testimony-section-title')
    const refTitle = document.getElementById('ref-section-title')

    const aboutOpacity = giveOpacityWithTransition(blocs[1]?.posY, blocs[1]?.posY + elements.about?.height, aboutTitle.offsetTop)
    if (aboutOpacity > 0) {
      aboutTitle.style.visibility = 'visible'
      aboutTitle.style.opacity = aboutOpacity;
      aboutTitle.style.color = giveColorWithTransition(blocs[2]?.posY + elements.activity?.posY, blocs[2]?.posY + elements.activity?.posY + elements.activity?.height - aboutTitle.offsetHeight, aboutTitle.offsetTop)
    } else {
      aboutTitle.style.visibility = 'hidden'
    }

    const actuOpacity = giveOpacityWithTransition(blocs[1]?.posY + elements.actu?.height, blocs[1]?.posY + elements.actu?.height + elements.about?.height - actuTitle.offsetHeight, actuTitle.offsetTop)
    if (actuOpacity > 0) {
      actuTitle.style.visibility = 'visible'
      actuTitle.style.opacity = actuOpacity
      actuTitle.style.color = giveColorWithTransition(blocs[2]?.posY + elements.activity?.posY, blocs[2]?.posY + elements.activity?.posY + elements.activity?.height, actuTitle.offsetTop)
    } else {
      actuTitle.style.visibility = 'hidden'
    }

    const activityOpacity = giveOpacityWithTransition(blocs[2]?.posY + elements.activity?.posY, blocs[2]?.posY + elements.activity?.posY + elements.activity?.height, activityTitle.offsetTop)
    if (activityOpacity > 0) {
      activityTitle.style.visibility = 'visible'
      activityTitle.style.opacity = activityOpacity
      activityTitle.style.color = giveColorWithTransition(blocs[2]?.posY + elements.activity?.posY, blocs[2]?.posY + elements.activity?.posY + elements.activity?.height, activityTitle.offsetTop)
    } else {
      activityTitle.style.visibility = 'hidden'
    }

    const testimonyOpacity = giveOpacityWithTransition(blocs[3]?.posY, blocs[3]?.posY + elements.testimony?.height, testimonyTitle.offsetTop)
    if (testimonyOpacity > 0) {
      testimonyTitle.style.visibility = 'visible'
      testimonyTitle.style.opacity = testimonyOpacity
      testimonyTitle.style.color = giveColorWithTransition(blocs[2]?.posY + elements.activity?.posY, blocs[2]?.posY + elements.activity?.posY + elements.activity?.height, testimonyTitle.offsetTop)
    } else {
      testimonyTitle.style.visibility = 'hidden'
    }

    const refOpacity = giveOpacityWithTransition(blocs[3]?.posY + elements.ref?.posY, blocs[3]?.posY + elements.ref?.posY + elements.ref?.height, refTitle.offsetTop)
    if (refOpacity > 0) {
      refTitle.style.visibility = 'visible'
      refTitle.style.opacity = refOpacity
      refTitle.style.color = giveColorWithTransition(blocs[2]?.posY + elements.activity?.posY, blocs[2]?.posY + elements.activity?.posY + elements.activity?.height, refTitle.offsetTop)
    } else {
      refTitle.style.visibility = 'hidden'
    }
  }
  // const refAbout = useRef(null)
  // const scrollAbout = () => { console.log("refAbout",refAbout); scrollToRef(refAbout); }
  // const refActualite = useRef(null)
  // const scrollActualite = () => { console.log("refActualite",refActualite); scrollToRef(refActualite) }
  // const refGd6d = useRef(null)
  // const scrollGd6d = () => { console.log("refGd6d",refGd6d); scrollToRef(refGd6d) }
  // const refInnovation = useRef(null)
  // const scrollInnovation = () => { console.log("refInnovation",refInnovation); scrollToRef(refInnovation) }

  const refAbout = useRef(null)
  const scrollAbout = () => { console.log("refAbout", refAbout); window.scrollTo({ top: blocs[1].posY + elements.about.posY, behavior: 'smooth' }); }
  const refActualite = useRef(null)
  const scrollActualite = () => { console.log("refActualite", refActualite); window.scrollTo({ top: blocs[1].posY + elements.actu.posY, behavior: 'smooth' }) }
  const refGd6d = useRef(null)
  const scrollGd6d = () => { console.log("refGd6d", refGd6d); scrollToRef(refGd6d) }
  const refInnovation = useRef(null)
  const scrollInnovation = () => { console.log("refInnovation", elements.innovation); window.scrollTo({ top: blocs[2].posY + elements.innovation.posY, behavior: 'smooth' }) }

  return (
    <div id='home-page' className="App">
      <Headbar
        logo={test}
        setIsOpen={setIsOpenDrawer}
        displayCollapse={displayCollapse}
        setDisplayCollapse={setDisplayCollapse}
        invertColor
        triggerAbout={scrollAbout}
        triggerInnovation={scrollInnovation}
        triggerGd6d={scrollGd6d}
        triggerActualite={scrollActualite}
      />
      <Headbar
        logo={test2}
        setIsOpen={setIsOpenDrawer}
        displayCollapse={displayCollapse}
        setDisplayCollapse={setDisplayCollapse}
        isFixed
        triggerAbout={scrollAbout}
        triggerInnovation={scrollInnovation}
        triggerGd6d={scrollGd6d}
        triggerActualite={scrollActualite}
      />

      <div style={{ position: 'relative' }}>

        <div id='bloc-1' className='bloc' style={{ top: '0' }}>
          <MainSection sectionId='main-section' />
        </div>

        <div id='bloc-2' className='bloc' style={{ top: 'calc(-' + blocs[1]?.height + 'px + 100vh)' }}>
          <div id='key-section-container' style={{ position: 'relative', top: '-40px' }}>
            <KeyFigures />
          </div>
          <div id='about-section-container'>
            <AboutSection givenRef={refAbout} sectionId='about-section' content={aboutSectionContent} />
          </div>
          <div id='actu-section-container' >
            <ActuSection refActualite={refActualite} />
          </div>
        </div>

        <div id='bloc-3' className='bloc' style={{ top: 'calc(-' + blocs[2]?.height + 'px + 100vh)' }}>
          <div id='activity-section-container'>
            <ActivitySection sectionId='gd6d' givenRef={refGd6d} content={gd6dSectionContent} url={process.env.REACT_APP_URL_GD6D} isReverse />
            <ActivitySection sectionId='innovation' givenRef={refInnovation} content={innovationSectionContent} />
          </div>
        </div>

        <div id='bloc-4' className='bloc' style={{ top: 'calc(-' + blocs[3]?.height + 'px + 100vh)' }}>
          {/* <div id='testimony-section-title' className='grid title-of-section' style={{ top: 'calc(40vh + 90px)', paddingBottom: '30px' }}>
              <span>Témoignages</span>
            </div> */}
          <div id='testimony-section-container'>
            <TestimonySection />
          </div>
          <div id='ref-section-container'>
            <RefSection />
          </div>
          <QuoteSection />
          <Footer />

          {/* <div id='ref-section-title' className='grid title-of-section' style={{ top: 'calc(40vh + 120px)', paddingBottom: '0' }}>
            <span>Références</span>
          </div> */}
        </div>

        <div className='hidden' style={{ position: 'absolute', top: '0', left: '40px', bottom: '0', width: `calc(100% / 12 - 20px)` }}>
          <div id='about-section-title' className='title-of-section' style={{ cursor: 'pointer', top: '40vh' }} onClick={() => window.scrollTo({ top: blocs[1]?.posY + elements.about?.posY, behavior: 'smooth' })}>
            <span>A propos</span>
          </div>
          <div id='actu-section-title' className='title-of-section' style={{ cursor: 'pointer', top: 'calc(40vh + 30px)' }} onClick={() => window.scrollTo({ top: blocs[1]?.posY + elements.actu?.posY, behavior: 'smooth' })}>
            <span>Notre actu</span>
          </div>
          <div id='activity-section-title' className='title-of-section' style={{ cursor: 'pointer', top: 'calc(40vh + 60px)' }} onClick={() => window.scrollTo({ top: blocs[2]?.posY + elements.activity?.posY, behavior: 'smooth' })}>
            <span>Nos activités</span>
          </div>
          <div id='testimony-section-title' className='title-of-section' style={{ cursor: 'pointer', top: 'calc(40vh + 90px)' }} onClick={() => window.scrollTo({ top: blocs[3]?.posY + elements.testimony?.posY, behavior: 'smooth' })}>
            <span>Témoignages</span>
          </div>
          <div id='ref-section-title' className='title-of-section' style={{ cursor: 'pointer', top: 'calc(40vh + 120px)' }} onClick={() => window.scrollTo({ top: blocs[3]?.posY + elements.ref?.posY, behavior: 'smooth' })}>
            <span>Références</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage
