import React, { useReducer, useEffect } from 'react'
import './Headbar.scss'

import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSmile, faEllipsisV } from '@fortawesome/free-solid-svg-icons'

import MenuMobile from '../MenuMobile'
import Headband from '../Headband'

const initialState = {
  scrollPosition: 0,
  isHidden: true
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOOGLE_MENU':
      return {
        ...state,
        scrollPosition: action.payload.scrollPosition,
        isHidden: action.payload.isHidden
      }
    default:
  }
}

const toogleMenu = (scrollPosition, isHidden, dispatch) => dispatch({ type: 'TOOGLE_MENU', payload: { scrollPosition, isHidden } })

const Headbar = ({ logo, setIsOpen, isFixed, invertColor, triggerAbout, triggerInnovation, triggerActualite, displayCollapse, setDisplayCollapse }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const displayHeadbar = () => {
      const previouslyPosition = state.scrollPosition
      const currentPosition = window.scrollY

      if (currentPosition < previouslyPosition && currentPosition > 100) toogleMenu(window.scrollY, false, dispatch)
      else if (currentPosition > state.scrollPosition || currentPosition < 100) toogleMenu(window.scrollY, true, dispatch)
    }

    window.addEventListener('scroll', displayHeadbar, { passive: true })

    return () => {
      window.removeEventListener('scroll', displayHeadbar)
    }
  }, [state.scrollPosition])

  return (
    <>
      <div className={`header ${isFixed ? (state.isHidden ? 'header-hidden header-fixed' : 'header-display header-fixed') : 'header-absolute'}`}>
        {false && <Headband />}
        <div className={`grid headbar-wrapper ${invertColor ? 'headbar-color-invert' : 'headbar-color'}`}>
          <div className='logo-wrapper flex-left-center-wrapper'>
            <img src={logo} alt="Logotype d'E3D-Environnement" />
          </div>
          <nav className='navigation flex-between-center-wrapper'>
            <ul className='menu'>
              <li className='menu-item'><a href={process.env.REACT_APP_URL_HOME}><span>Accueil</span></a></li>
              <li className='menu-item'>
                {process.env.REACT_APP_PAGE === "home" && <a onClick={() => { if (triggerAbout) { triggerAbout(); } else { console.log("No function triggerAbout"); } }}><span>Qui sommes-nous ?</span></a>}
                {process.env.REACT_APP_PAGE !== "home" && <a href={process.env.REACT_APP_URL_HOME + "#about"}><span>Qui sommes-nous ?</span></a>}
              </li>
              <li className='menu-item'>
                {process.env.REACT_APP_PAGE === "home" && <a onClick={() => { if (triggerActualite) { triggerActualite(); } else { console.log("No function triggerActualite"); } }}><span>Notre actualité</span></a>}
                {process.env.REACT_APP_PAGE !== "home" && <a href={process.env.REACT_APP_URL_HOME + "#actualite"}><span>Notre actualité</span></a>}
              </li>
              <li className='menu-item'>
                {process.env.REACT_APP_PAGE === "home" && <a onClick={() => { if (triggerInnovation) { triggerInnovation(); } else { console.log("No function triggerInnovation"); } }}><span>Innovation</span></a>}
                {process.env.REACT_APP_PAGE !== "home" && <a href={process.env.REACT_APP_URL_HOME + "#innovation"}><span>Innovation</span></a>}
              </li>
              <li className='menu-item'><a href={process.env.REACT_APP_URL_GD6D}><span>GD6D</span></a></li>
              {false && <li className='menu-item COVID'><a href={process.env.REACT_APP_URL_COVID}><span>COVID-19</span></a></li>}
            </ul>
            <a className='btn' onClick={() => setIsOpen(true)}>
              <FontAwesomeIcon icon={faSmile} />
              <span>Nous rejoindre</span>
            </a>
          </nav>
          <div className='navigation-collapse'>
            <span onClick={() => setDisplayCollapse(true)} style={{ color: invertColor ? '#fff' : '#212121' }}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </span>
          </div>
        </div>
      </div>
      <MenuMobile displayCollapse={displayCollapse} setDisplayCollapse={setDisplayCollapse} triggerAbout={triggerAbout} triggerInnovation={triggerInnovation} triggerActualite={triggerActualite} />
    </>
  )
}

Headbar.propTypes = {
  logo: PropTypes.string,
  setIsOpen: PropTypes.func,
  isFixed: PropTypes.bool,
  invertColor: PropTypes.bool
}

Headbar.defaultProps = {
  isFixed: false,
  invertColor: false,
}

export default Headbar
