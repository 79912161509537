import React, { useState, useEffect } from 'react'
import './Drawer.scss'

import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode, faCoffee, faTimes, faEnvelope, faGrinBeamSweat, faGlobe, faUserTie, faChartPie, faGraduationCap, faLink } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'

const jobsOld = [
  { intitule: 'Développeur Web Fullstack (PHP) H/F', icon: faCode, link: 'https://www.welcometothejungle.com/fr/companies/e3d-environnement/jobs/developpeur-web-fullstack-php-h-f_aix-en-provence' },
  { intitule: 'Développeur Front-End React / Ergonome H/F', icon: faCode, link: 'https://www.welcometothejungle.com/fr/companies/e3d-environnement/jobs/developpeur-front-end-react-h-f_aix-en-provence' },
  //{ intitule: 'Développeur Back-End - PHP/MySQL H/F', icon: faCode, link: 'https://www.apec.fr/candidat/recherche-emploi.html/emploi/detail-offre/168134918W' },
  //{ intitule: 'Développeur Front-End - React H/F', icon: faCode, link: 'https://www.apec.fr/candidat/recherche-emploi.html/emploi/detail-offre/168135175W' },
  //{ intitule: 'Docteur/Expert en Sciences Humaines et Sociales H/F', icon: faGlobe, link: 'https://www.apec.fr/candidat/recherche-emploi.html/emploi/detail-offre/168007045W' },
  { intitule: 'Docteur/Expert en Sciences Humaines et Sociales H/F', icon: faGraduationCap, link: 'https://www.welcometothejungle.com/fr/companies/e3d-environnement/jobs/expert-en-sciences-humaines-et-sociales_aix-en-provence' },
  //{ intitule: 'New', icon: faLink, link:'' },
  //{ intitule: 'Chef de projet méditerranéen H/F', icon: faGlobe, link: '' },
  { intitule: 'Commercial H/F', icon: faGlobe, link: 'https://www.talentdetection.com/e3d-environnement/offre-69199-FvhfpK' },
  //{ intitule: 'Chef équipe animation téléphonique H/F', icon: faLink, link: '' },
  //{ intitule: 'Animateur téléphonique H/F', icon: faLink, link: '' },
  { intitule: 'Conseiller particulier H/F', icon: faUserTie, link: 'https://www.talentdetection.com/e3d-environnement/offre-69199-BX4AkP' },
  //{ intitule: 'Chargé de communication H/F', icon: faGlobe, link: 'https://www.talentdetection.com/e3d-environnement/offre-69199-KREC74' },
  /*
    * Dr en sciences sociales
    * chef de projet méditerranéen
    * commercial (à compléter : junior/confirmé plutôt que junior qui nous enlève de bons profils)
    * chef d'équipe animation tél
    * animateurs tél.
    * conseiller particulier : \\192.168.1.12\Administratif\1- Gestion du pôle RH\1- Recrutements\9- Pôle DG
    * chargé de com
  */
]

const giveIcon = (textIcon) => {
  if (textIcon === 'faGlobe') {
    return faGlobe
  }
  if (textIcon === 'faUserTie') {
    return faUserTie
  }
  if (textIcon === 'faCode') {
    return faCode
  }
  if (textIcon === 'faGraduationCap') {
    return faGraduationCap
  }
  return faEnvelope
}

const Drawer = ({ isOpen, setIsOpen }) => {
  const [onHover, setOnHover] = useState(false)

  const [jobs, setJobs] = useState([])

  useEffect(() => {
    axios.get('https://www.e3d-environnement.com/json/jobs.php')
      .then(res => {
        const data = res.data
        const tabJobs = data.filter((j) => {
          let actif = true
          try { actif = !!j.actif } catch (error) { }
          //console.log("Filter", { actif, input: j.actif, test: j.actif == true })
          return actif
        }).map((j, i) => {
          // Champs utiles
          let title = null
          try { title = j.intitule } catch (error) { }
          let link = null
          try { link = j.link } catch (error) { }
          let icon = faChartPie
          try { icon = giveIcon(j.icon) } catch (error) { }
          // Renvoi
          return {
            intitule: title,
            icon,
            link
          }
        })
        setJobs(tabJobs)
        //console.log("JOBS", data, tabJobs)
      })
      .catch(err => console.log("Erreur API", err))
  }, [])

  useEffect(() => {
    if (isOpen) {
      const scrollPos = window.pageYOffset // Attrape le niveau de scroll
      document.body.classList.add('drawer-is-open') // Ajoute une class qui rend fixe le body
      document.body.style.top = -1 * scrollPos + 'px' // Ajoute le niveau de scroll au 'top' du body fixe
    } else {
      document.body.classList.remove('drawer-is-open') // Enleve la class modal-open de mon obdy
      let value = window.document.body.style.top // Recupère mon niveau de scroll d'avant l'ajout d'un position fixe à mon document
      value = value.replace('px', '')
      window.scrollTo(0, -1 * value) // Remet le scroll en place
    }
  }, [isOpen])

  return (
    <div className='drawer'>
      <div className='drawer-background' onClick={() => setIsOpen(false)} style={isOpen ? { opacity: 1 } : { visibility: 'hidden', opacity: 0 }}></div>
      <div className={`drawer-wrapper drawer-${isOpen ? 'open' : 'close'}`}>
        <div
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          onClick={() => setIsOpen(false)}
          className='close-icon' style={{ transform: onHover ? 'rotate(90deg) scale(1.25)' : 'rotate(0deg) scale(1)' }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className='drawer-content' style={isOpen ? { opacity: 1 } : { visibility: 'hidden', opacity: 0 }}>
          <ul className='contact'>
            <li><strong>E3D-ENVIRONNEMENT</strong></li>
            <li>1 place Martin Luther King,13090 Aix-en-Provence</li>
            <li className='menu-item-rs'>
              <span className='menu-rs'>
                {false && <a><FontAwesomeIcon icon={faTwitter} /></a>}
                <a href="https://www.linkedin.com/company/e3d-environnement" target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a>
                {false && <a><FontAwesomeIcon icon={faYoutube} /></a>}
                <a href="mailto:contact@e3d-environnement.com" target="_blank"><FontAwesomeIcon icon={faEnvelope} /></a>
                <a href="tel:+33442680899" target="_blank">04 42 68 08 99</a>
              </span>
            </li>
          </ul>
          <div className='drawer-jobs'>
            <div className='title-of-section-drawer'>Les talents que nous recherchons</div>
            <div className='drawer-jobs-content'>
              {jobs && jobs.length > 0 &&
                <>
                  <ul className='jobs-list' style={jobs.length > 0 ? { overflow: "scroll", maxHeight: "470px" } : {}}>
                    {jobs.map(job => <li key={job.intitule}><span><FontAwesomeIcon icon={job.icon} /></span><span>{job.intitule}</span>{job.link && <a style={{ marginLeft: '10px' }} href={job.link} target="_blank"><FontAwesomeIcon icon={faLink} /></a>}</li>)}
                  </ul>
                  <br />
                  <br />
                </>
              }
              {(!jobs || jobs.length === 0) &&
                <div>
                  <FontAwesomeIcon icon={faGrinBeamSweat} />
                  <span>Il semble que notre équipe de talents<br />soit actuellement au complet</span>
                  <span>Cependant vous pouvez nous envoyer votre candidature spontanée,<br />on sera ravis de l'étudier et d'échanger avec vous !</span>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Drawer
