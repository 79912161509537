import React, { useState } from 'react'
import './TargetPanel.scss'

import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect';


const TargetPanel = ({ target, content }) => {
  const { surtitle, title, quote, contentLeft, contentRight } = content

  const [isOpen, setIsOpen] = useState(false)
  const [isHover, setIsHover] = useState(false)

  return (
    <section className='target-panel shadow-target'>
      <div className={`target-panel-left ${target}-color-bg`}>
        <div className='content'>
          <p className='content-title'>{surtitle}</p>
          <h3>{title}</h3>
          <p className='content-body'><strong>{contentLeft[0]}</strong></p>
          <p className='content-body'>{contentLeft[1]}</p>
          <div
            className='btn-invert'
            onClick={() => setIsOpen(!isOpen)}
            onMouseEnter={() => setIsHover(!isMobile)}
            onMouseLeave={() => setIsHover(false)}
          >
            <span>En voir {isOpen ? 'moins' : 'plus'}</span>
          </div>
        </div>
      </div>

      <div className='target-panel-right'>
        <div className={`panel-right-wrapper ${isHover ? '' : isOpen ? 'panel-right-open' : 'panel-right-close'} ${isHover ? isOpen ? 'panel-right-open-hover' : 'panel-right-close-hover' : ''}`}>

          <div className='panel-right-content'>
            <div className='grid'>
              <div className='panel-right-content-col1'>
                <h5 className={`${target}-color`}>{contentRight[0].title}</h5>
                <ul>
                  {
                    contentRight[0].content.map((el, i) => <li key={i}><div dangerouslySetInnerHTML={{ __html: el }}></div></li>)
                  }
                </ul>
              </div>
              <div className='panel-right-content-col2'>
                <h5 className={`${target}-color`}>{contentRight[1].title}</h5>
                <ul>
                  {
                    contentRight[1].content.map((el, i) => <li key={i}><div dangerouslySetInnerHTML={{ __html: el }}></div></li>)
                  }
                </ul>
              </div>
            </div>
          </div>

          <div className={`target-figure ${target}-img`}>
            <div className='citation-wrapper'>
              <blockquote className='citation'>{quote.content}</blockquote>
              <span className={`citation-author ${target}-color`}>{quote.author}</span>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

TargetPanel.propTypes = {
  target: PropTypes.string,
  content: PropTypes.object,
}

export default TargetPanel
