import React, { useState, useEffect } from 'react'
import './GD6DPage.scss'

import content from '../../content/content-gd6d.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

import Headbar from '../../components/Headbar'
import AboutSection from '../../components/AboutSection'
import TargetPanel from '../../components/TargetPanel'
import Footer from '../../components/Footer'

import test2 from '../../test2.png'

const GD6DPage = ({ setIsOpenDrawer, displayCollapse, setDisplayCollapse }) => {
  const [main, setMain] = useState({})
  const [targets, setTargets] = useState([])
  const [targetsElements, setTargetsElements] = useState([])

  const handleScrollTo = (e, target, i) => {
    e.preventDefault()
    //console.log(target)
    const findTargetPosition = targetsElements.find(targetEl => targetEl.name === target)
    let targetPosition = findTargetPosition.posY
    //console.log(targetPosition , window.scrollY);
    if (targetPosition > window.scrollY) {
      targetPosition = targetPosition * 1 + 65;
    }
    //console.log("New ",targetPosition);
    window.scrollTo({ top: targetPosition, behavior: 'smooth' })
  }

  useEffect(() => {
    let extractTargets = []
    Object.keys(content.targets).forEach(key => {
      const target = { target: key, content: content.targets[key] }
      extractTargets.push(target)
    })
    setTargets(extractTargets)
    setMain(content.main)
  }, [])

  useEffect(() => {
    const formatDataTargets = () => {
      window.scrollTo({ top: 0 })
      let elements = [{ height: document.getElementById('gd6d-about-container').height }]
      elements = targets.map((item, idx) => (
        {
          index: idx,
          name: item.target,
          element: document.getElementById(`${item.target}-panel`),
          posY: document.getElementById(`${item.target}-panel`).offsetTop,
          height: document.getElementById(`${item.target}-panel`).offsetHeight
        }
      ))
      setTargetsElements(elements)
    }

    formatDataTargets()
  }, [targets])

  useEffect(() => {
    window.addEventListener('scroll', () => handleScroll(targetsElements))
    window.addEventListener('resize', () => handleScroll(targetsElements))
    return () => {
      window.removeEventListener('scroll', () => handleScroll(targetsElements))
      window.removeEventListener('resize', () => handleScroll(targetsElements))
    }
  }, [targetsElements])

  const handleScroll = (elements) => {
    const breadcrumbHeight = document.getElementById('breadcrumb-list').offsetHeight
    const selector = document.getElementById('selector')
    const currentPos = window.scrollY

    const baseSelectorPos = breadcrumbHeight / (elements.length + 1)
    const offsetSelectorPos = 10;

    elements.forEach(el => {
      const bullet = document.getElementById(`${el.name}-bullet`)
      const title = document.getElementById(`${el.name}-title`)
      if (currentPos < elements[0].posY - (elements[0].height / 2)) {
        selector.style.top = `${offsetSelectorPos}px`
        selector.style.border = '2px solid #212121'
        bullet.classList.remove('bg-white')
        title.style.opacity = '0'
        title.style.transform = 'translateY(-25px)'
      } else if (currentPos > el.posY - (el.height / 2) && currentPos < el.posY + (el.height / 2)) {
        const newPos = (baseSelectorPos * (el.index + 1)) + offsetSelectorPos
        selector.style.top = `${newPos}px`
        selector.style.border = '2px solid #fff'
        bullet.classList.add('bg-white')
        title.style.opacity = '1'
        title.style.transform = 'translateY(0)'
      } else {
        bullet.classList.remove('bg-white')
        title.style.opacity = '0'
      }
    })
  }

  return (
    <div id='gd6d-page' className='App'>
      <Headbar logo={test2} setIsOpen={setIsOpenDrawer} displayCollapse={displayCollapse} setDisplayCollapse={setDisplayCollapse} />

      <div id='breadcrumb'>
        <div>
          <div id='selector'></div>
          <ul id='breadcrumb-list'>
            <li ><span id='home-bullet' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}><FontAwesomeIcon icon={faHome} /></span></li>
            {targets.map((item, index) =>
              <li key={item.target}>
                <span
                  id={`${item.target}-bullet`}
                  className={`${item.target}-color-bg bullet`}
                  onClick={e => handleScrollTo(e, item.target, index)}
                ></span>
                <span
                  id={`${item.target}-title`}
                  className='bullet-title'
                >{item.content.surtitle}</span>
              </li>
            )}
          </ul>
        </div>
      </div>

      <div id='target-container' style={{ position: 'relative' }}>
        <div
          id='gd6d-about-container'
          className='sal-wrapper safe-zone'
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '70px 0 70px', top: `calc(-${targetsElements[0]?.height}px + 100vh)` }}>
          <AboutSection sectionId='gd6d-about-section' content={main} />
        </div>
        {
          targets.map((item, i) => (
            <div key={item.target} id={`${item.target}-panel`} style={{ position: 'sticky', top: `calc(-${targetsElements[i + 1]?.height}px + ${targetsElements[i + 1]?.height < window.innerHeight ? targetsElements[i + 1]?.height + 'px' : '100vh'})` }}>
              <TargetPanel target={item.target} content={item.content} />
            </div>
          )
          )}
      </div>
      <Footer />
    </div>
  )
}

export default GD6DPage
