import React from 'react'
import './MenuMobile.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'

const MenuMobile = ({ displayCollapse, setDisplayCollapse, triggerAbout, triggerInnovation, triggerActualite }) => {
  const handletriggerAbout = () => {
    setDisplayCollapse(false)
    triggerAbout()
  }
  const handletriggerInnovation = () => {
    setDisplayCollapse(false)
    triggerInnovation()
  }
  const handletriggerActualite = () => {
    setDisplayCollapse(false)
    triggerActualite()
  }

  return (
    <div className='menu-mobile' style={displayCollapse ? { opacity: 1 } : { visibility: 'hidden', opacity: 0 }}>
      <div onClick={() => setDisplayCollapse(false)} className='close-icon'><FontAwesomeIcon icon={faTimes} /></div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <nav className='flex-center-center-column'>
          <ul className='menu'>
            <li className='menu-item'><a href={process.env.REACT_APP_URL_HOME}><span>Accueil</span></a></li>
            <li className='menu-item'>
              {process.env.REACT_APP_PAGE === "home" && <a onClick={handletriggerAbout}><span>Qui sommes-nous ?</span></a>}
              {process.env.REACT_APP_PAGE !== "home" && <a href={process.env.REACT_APP_URL_HOME + "#about"}><span>Qui sommes-nous ?</span></a>}
            </li>
            <li className='menu-item'>
              {process.env.REACT_APP_PAGE === "home" && <a onClick={handletriggerActualite}><span>Notre actualité</span></a>}
              {process.env.REACT_APP_PAGE !== "home" && <a href={process.env.REACT_APP_URL_HOME + "#actualite"}><span>Notre actualité</span></a>}
            </li>
            <li className='menu-item'>
              {process.env.REACT_APP_PAGE === "home" && <a onClick={handletriggerInnovation}><span>Innovation</span></a>}
              {process.env.REACT_APP_PAGE !== "home" && <a href={process.env.REACT_APP_URL_HOME + "#innovation"}><span>Innovation</span></a>}
            </li>
            <li className='menu-item'><a href={process.env.REACT_APP_URL_GD6D}><span>GD6D</span></a></li>
            {false && <li className='menu-item'><a href={process.env.REACT_APP_URL_COVID}><span>- Gestion COVID-19 -</span></a></li>}
            <li className='menu-item-rs'>
              <span className='menu-rs'>
                {false && <a><FontAwesomeIcon icon={faTwitter} /></a>}
                <a href="https://www.linkedin.com/company/e3d-environnement" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
                {false && <a><FontAwesomeIcon icon={faYoutube} /></a>}
                <a href="mailto:contact@e3d-environnement.com" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faEnvelope} /></a>
                <a href="tel:+33442680899" target="_blank">04 42 68 08 99</a>
              </span>
            </li>
          </ul>
        </nav>
        <div className='contact'>
          <span>E3D-ENVIRONNEMENT</span>
          <span>1 place Martin Luther King,<br />13090 Aix-en-Provence</span>
        </div>
      </div>
    </div>
  )
}

export default MenuMobile
