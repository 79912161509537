import React, { useState, useRef } from 'react'
import './KeyFigures.scss'

import KeyFigureCard from '../KeyFigureCard'

const KeyFigures = () => {
  const [isScrolling, setIsScrolling] = useState(false)
  const [clientX, setClientX] = useState(0)
  const [scrollX, setScrollX] = useState(0)
  const ref = useRef()

  const handleMouseDown = e => {
    setIsScrolling(true)
    setClientX(e.clientX)
  }
  const handleMouseUp = () => {
    setIsScrolling(false)
  }
  const handleMouseMove = e => {
    if (isScrolling) {
      console.log(scrollX, e.clientX, clientX)
      const scrollValue = scrollX + clientX - e.clientX
      const scrollValueConstrain = Math.min(Math.max(0, scrollValue), ref.current.children[0].offsetWidth)
      ref.current.scrollLeft = scrollValueConstrain
      setScrollX(scrollValueConstrain)
      setClientX(e.clientX)
    }
  }

  return (
    <section id='key-figures'>
      <div
        ref={ref}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseOut={handleMouseUp}
        className='key-figures-wrapper'
      >
        <div className='grid'>
          <KeyFigureCard value='12' name="Années d'experience" />
          <KeyFigureCard value='60' name="Collectivités accompagnées" />
          <KeyFigureCard value='46000' name="Nouveaux comportements adoptés en 12 ans" incrementConfig={{ start: new Date('01/07/2010'), delay: 4000000, inc: 1 }} />
          <KeyFigureCard value='30000' name="Echanges habitants en 2023" incrementConfig={{ start: new Date('01/01/2023'), delay: 36000, inc: 1 }} />
        </div>
      </div>
    </section>
  )
}

export default KeyFigures
