import React from 'react'
import { Modal, Descriptions } from 'antd';

const MentionsLegales = (props) => {
  const {
    open,
    handleClose
  } = props
  return (
    <>
      <Modal title="" open={open} onCancel={handleClose} footer={null} width={850}>

        <Descriptions
          className="e3d-descriptions_nopadding"
          title="Mentions Légales"
          column={1}
        >
          <Descriptions.Item label="URL">www.e3d-environnement.com</Descriptions.Item>
          <Descriptions.Item label="Société">E3D-Environnement</Descriptions.Item>
          <Descriptions.Item label="Adresse">1 place Martin Luther King, 13090 Aix-en-Provence</Descriptions.Item>
          <Descriptions.Item label="Capital social">125.000 €</Descriptions.Item>
          <Descriptions.Item label="RCS">518 539 085</Descriptions.Item>
          <Descriptions.Item label="Tél.">+33 442 680 899</Descriptions.Item>
          <Descriptions.Item label="Mail"><a href="mailto:contact@e3d-environnement.com">contact@e3d-environnement.com</a></Descriptions.Item>
        </Descriptions>

        <br />

        <Descriptions
          className="e3d-descriptions_nopadding"
          title="Contenus"
          column={1}
        >
          <Descriptions.Item label="Directeur de publication, représentant légal">M. Gilles MARCHAL</Descriptions.Item>
          <Descriptions.Item label="Conception et design du site web">E3D-Environnement</Descriptions.Item>
          <Descriptions.Item label="Hébergement">OVH, 2 rue Kellermann, BP 80157, 59053 Roubaix cedex 1</Descriptions.Item>
          <Descriptions.Item label="Crédits Photos">Adobe Stock</Descriptions.Item>
        </Descriptions>

        <p>
          Loi informatique et liberté : Tout utilisateur dispose d’un droit d’accès, de modification, de rectification et de suppression des données qui le concerne, conformément à la loi n° 7817 du 6/01/78 (Informatique et Liberté), en s’adressant à contact@e3d-environnement.com
        </p>

        <p>
          Propriété : Le site et les informations qu’il contient sont protégés par la loi française sur la propriété intellectuelle et par les conventions internationales. Hors utilisation dans le cercle de famille ou usage privé, le site et aucun élément de son contenu ne peuvent être reproduits, republiés, retranscrits, modifiés ou retransmis sans l’autorisation préalable des ayants droit.
        </p>

      </Modal>
    </>
  );
};
export default MentionsLegales;
